(function($) {
    "use strict";

    $.ajaxPrefilter(function(options) {
        var token = $("input[name^=__RequestVerificationToken]").val();
        options.headers = { RequestVerificationToken: token };
    });

    window.App = {
        go: function(url) {
            window.location = url;
        },

        initPage: function() {
            if ($.fn.datepicker)
                $("input.js-datepicker").datepicker({
                    todayBtn: "linked",
                    clearBtn: true,
                    calendarWeeks: true,
                    autoclose: true,
                    todayHighlight: true
                });

            if ($.fn.mvcgrid)
                $(".mvc-grid").mvcgrid();
        },

        confirmDelete: function(entity) {
            if (!entity)
                entity = "record";
            return confirm("Do you wish to delete the " + entity + "?");
        }
    };
    App.initPage();

    
      tinymce.init({
          selector: 'textarea.richtext'
      });
    

})(jQuery);